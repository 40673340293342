var _dec, _class;
import { Options, Vue } from 'vue-class-component';
import { BANNER_CONFS } from './consts';
import FreeExperience from '@/components/FreeExperience';
let View = (_dec = Options({
  components: {
    FreeExperience
  },
  data() {
    return {
      BANNER_CONFS,
      visibleFreeExperience: false
    };
  }
}), _dec(_class = class View extends Vue {}) || _class);
export { View as default };