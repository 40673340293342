export const BANNER_CONFS = [
	{
		title: '通过科技赋能让健康管理更简单',
		subTitle: '基于微信生态和AIoT技术的健康管理平台',
		image: require('../../images/banner-1.png'),
	},
	{
		title: '完整的数字健康解决方案服务商',
		subTitle: '平台+小程序+便携式健康监测设备+运营服务',
		image: require('../../images/banner-2.png'),
	},
	{
		title: '覆盖全行业，满足全场景健康管理业务需求',
		subTitle: '健康管理中心+企业+医院+诊所+社区+药店',
		image: require('../../images/banner-3.png'),
	},
];
