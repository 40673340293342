export const MENU_OPTIONS = [
	{
		title: '首页',
		path: '/',
	},
	{
		title: '产品与服务',
		path: '/xxx',
	},
	{
		title: '行业解决方案',
		path: '/xxx',
	},
	{
		title: '客户案例',
		path: '/xxx',
	},
	{
		title: '渠道合作',
		path: '/xxx',
	},
	{
		title: '关于我们',
		path: '/xxx',
	},
];
