var _dec, _class;
import { Options, Vue } from 'vue-class-component';
import Banner from './components/Banner';
import CoreProductCapabilities from './components/core-product-capabilities';
import MainApplicationScenarios from './components/main-application-scenarios';
import ProfessionalServiceSupport from './components/professional-service-support';
let HomeView = (_dec = Options({
  components: {
    Banner,
    CoreProductCapabilities,
    MainApplicationScenarios,
    ProfessionalServiceSupport
  },
  data() {
    return {};
  }
}), _dec(_class = class HomeView extends Vue {}) || _class);
export { HomeView as default };