export const OPTIONS = [
	{
		title: '检后健康管理',
		intro: '有效支撑体检中心开展检后健康管理业务，提升在线服务能力，提高客户服务满意度和营业收入',
		icon: require('./images/sy_icon_cj_1.svg'),
	},
	{
		title: '会员健康管理',
		intro: '帮助医药公司、医疗器械公司、健康管理公司建立数字化健康管理服务体系，通过技术赋能为客户创造价值，有效助力企业服务用户',
		icon: require('./images/sy_icon_cj_2.svg'),
	},
	{
		title: '社区健康管理',
		intro: '通过监测设备采集居民的健康信息，并提供电子健康档案管理、健康干预、健康随访等服务，提升居民健康水平和健康素养，同时降低医疗费用开支',
		icon: require('./images/sy_icon_cj_3.svg'),
	},
	{
		title: '药店慢病管理',
		intro: '通过线下+线上相结合的模式，将慢病人群高频的生活方式管理与用户的购药消费相结合，为慢病人群提供全方位的健康管理计划，提升药店服务能力，通过数据运营反哺药店进行产品营销',
		icon: require('./images/sy_icon_cj_4.svg'),
	},
	{
		title: '保险健康管理',
		intro: '支持保险公司将商业健康保险产品相结合的疾病预防、健康维护、慢性病管理等健康管理服务，支持保险机构参与健康服务业产业链整合，重塑行业价值',
		icon: require('./images/sy_icon_cj_5.svg'),
	},
	{
		title: '地产健康管理',
		intro: '打造集医学健身、健康管理、健康教育、健康新零售等为一体的健康服务站，为业主提供全场景、全方位、全周期的健康服务，在提供优质住宅同时，也为业主提供优良的健康管家服务',
		icon: require('./images/sy_icon_cj_6.svg'),
	},
	{
		title: '康养健康管理',
		intro: '应用于中医养生会所、养生旅游景点、美容美体中心、中医相关机构、康养地产、保健养生会所等',
		icon: require('./images/sy_icon_cj_7.svg'),
	},
	{
		title: '企业健康管理',
		intro: '根据不同企业客户的需求，提供方便快捷安全的健康管理解决方案，包括私人医生、团体体检、员工健康管理计划等，保障企业员工的身心健康',
		icon: require('./images/sy_icon_cj_8.svg'),
	},
	null,
];
