export const OPTIONS = [
	{
		title: '用户运营',
		items: [
			{
				img: require('./images/img_1_1.png'),
				icons: [
					require('./images/icon_sy_1.svg'),
					require('./images/icon_sy_1_s.svg'),
				],
				title: '渠道获客',
				intro: '整合微信公众号、小程序、朋友圈、抖音、快手等多种流量终端，占据每个流量聚集入口，帮助客户完成品牌展示、引流获客与营销转化，构建品牌营销闭环。',
			},
			{
				img: require('./images/img_1_2.png'),
				icons: [
					require('./images/icon_sy_2.svg'),
					require('./images/icon_sy_2_s.svg'),
				],
				title: '标签管理',
				intro: '支持自动给每位客户打标签做分组，对不同的用户特征、行为、需求等做针对性的运营动作，大幅提升运营效率。',
			},
			{
				img: require('./images/img_1_3.png'),
				icons: [
					require('./images/icon_sy_3.svg'),
					require('./images/icon_sy_3_s.svg'),
				],
				title: '数据分析',
				intro: '通过收集和分析用户相关的数据为决策赋能，协助医疗机构更好地了解用户需求和行为，优化产品和服务的策略和设计，提高用户满意度和忠诚度。',
			},
		],
	},
	{
		title: '健康管理',
		items: [
			{
				img: require('./images/img_2_1.png'),
				icons: [
					require('./images/icon_sy_4.svg'),
					require('./images/icon_sy_4_s.svg'),
				],
				title: '健康服务',
				intro: '根据不同的场景提供健康管理数字化工具，以平台+小程序+智能硬件的方式，全面满足健康管理需求，包括健康档案、健康测评、健康干预、健康随访、健康咨询、健康教育等。',
			},
			{
				img: require('./images/img_2_2.png'),
				icons: [
					require('./images/icon_sy_5.svg'),
					require('./images/icon_sy_5_s.svg'),
				],
				title: '健康监测',
				intro: '基于物联网健康数据平台采集实时的健康监测信息，医护人员可根据用户的健康动态提供及时的健康服务，包括血糖、血压、心率、尿酸、血酮、体脂等，并支持根据疾病类型提供风险预警服务。',
			},
			{
				img: require('./images/img_2_3.png'),
				icons: [
					require('./images/icon_sy_6.svg'),
					require('./images/icon_sy_6_s.svg'),
				],
				title: '智能随访',
				intro: '基于AI能力，高效赋能医护团队，更加智能和高效地对患者进行批量自动化随访，提高随访对象的覆盖率，降低随访人工成本、优化用户体验，提升服务效果和服务口碑。',
			},
		],
	},
	{
		title: '变现能力',
		items: [
			{
				img: require('./images/img_3_1.png'),
				icons: [
					require('./images/icon_sy_7.svg'),
					require('./images/icon_sy_7_s.svg'),
				],
				title: '商品交易',
				intro: '搭建专属的健康商城，支持线上全渠道开店，海量曝光，提供丰富的营销工具支持，拉新、转化、促活、复购、裂变面面俱到，全面构建经营闭环。',
			},
			{
				img: require('./images/img_3_2.png'),
				icons: [
					require('./images/icon_sy_8.svg'),
					require('./images/icon_sy_8_s.svg'),
				],
				title: '订单管理',
				intro: '支持多条件查询订单，可按多个维度导出下单、交易等记录，及时掌握每个订单的最新状态，还能追踪实物商品的物流状态。',
			},
			{
				img: require('./images/img_3_3.png'),
				icons: [
					require('./images/icon_sy_9.svg'),
					require('./images/icon_sy_9_s.svg'),
				],
				title: '交易分析',
				intro: '时掌握用户动态及消费记录，对用户访问的行为和特征进行自动标签化，支持快速挖掘用户偏好和价值，识别用户消费意向，提高转化率。',
			},
		],
	},
];
