export const OPTIONS = [
	{
		title: '极速响应',
		intro: '7×12小时不间断在线，留言5分钟内极速响应',
	},
	{
		title: '高效反馈',
		intro: '客户需求提出24小时内高效反馈，第一时间服务',
	},
	{
		title: '全天候服务',
		intro: '全天候服务护航保障客户业务顺利开展',
	},
	{
		title: '私有化部署',
		intro: '私有化部署和培训服务，提供持续性维护与更新',
	},
	{
		title: '定制化开发',
		intro: '根据客户的需求和实际应用场景提供定制开发服务',
	},
];
