import { useRouter } from 'vue-router';
import { routes } from '@/router';
import { MENU_OPTIONS } from './consts';
import FreeExperience from '@/components/FreeExperience';
export default {
  components: {
    FreeExperience
  },
  data() {
    return {
      routes,
      MENU_OPTIONS,
      visibleFreeExperience: false
    };
  },
  computed: {
    defaultActive() {
      return useRouter().currentRoute.value.path;
    }
  },
  mounted() {
    // const router = useRouter();
    // console.log(router);
    // console.log(this.$router);
  }
};