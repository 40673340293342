import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Layout from '@/layout';
import HomeView from '@/views/home';

export const routes = [
	{
		path: '/',
		name: '首页',
		component: HomeView,
	},
	{
		path: '/products-and-services',
		name: '产品与服务',
		component: Layout,
		children: [
			{
				path: 'digital-health-platform',
				name: '数字健康平台',
				component: () => import('@/views/products-and-services'),
				meta: {
					type: 'digital-health-platform',
				},
			},
			{
				path: 'user-mini-program',
				name: '用户小程序',
				component: () => import('@/views/products-and-services'),
				meta: {
					type: 'user-mini-program',
				},
			},
			{
				path: 'micro-mall',
				name: '微商城',
				component: () => import('@/views/products-and-services'),
				meta: {
					type: 'micro-mall',
				},
			},
			// {
			// 	path: 'micro-customer-service',
			// 	name: '微客服',
			// 	component: () => import('@/views/products-and-services'),
			// 	meta: {
			// 		type: 'micro-customer-service',
			// 	},
			// },
		],
	},
	{
		path: '/industry-solutions',
		name: '行业解决方案',
		component: Layout,
		children: [
			{
				path: 'post-examination-health-management',
				name: '健康体检中心', // 健康体检中心
				component: () => import('@/views/industry-solutions'),
				meta: {
					type: 'post-examination-health-management',
				},
			},
			{
				path: 'user-health-management',
				name: '医疗服务公司', // 用户健康管理
				component: () => import('@/views/industry-solutions'),
				meta: {
					type: 'user-health-management',
				},
			},
			{
				path: 'community-health-management',
				name: '社区服务中心', // 社区健康管理
				component: () => import('@/views/industry-solutions'),
				meta: {
					type: 'community-health-management',
				},
			},
			{
				path: 'pharmacy-chronic-disease-management',
				name: '连锁药店', // 药店慢病管理
				component: () => import('@/views/industry-solutions'),
				meta: {
					type: 'pharmacy-chronic-disease-management',
				},
			},
			{
				path: 'medical-device-company',
				name: '医疗器械公司', // 医疗器械公司
				component: () => import('@/views/industry-solutions'),
				meta: {
					type: 'medical-device-company',
				},
			},
			{
				path: 'insurance-company',
				name: '保险公司', // 保险公司
				component: () => import('@/views/industry-solutions'),
				meta: {
					type: 'insurance-company',
				},
			},
			{
				path: 'clinic',
				name: '诊所', // 诊所
				component: () => import('@/views/industry-solutions'),
				meta: {
					type: 'clinic',
				},
			},
		],
	},
	{
		path: '/customer-case',
		name: '客户案例',
		component: () => import('@/views/customer-case'),
	},
	{
		path: '/channel-cooperation',
		name: '渠道合作',
		component: () => import('@/views/channel-cooperation'),
	},
	{
		path: '/about',
		name: '关于我们',
		component: () => import('@/views/about'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

function sleep(ms = 0) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(null), ms);
    });
};
function calcDistanceToTop(el) {
	var top = 0;
	while (el) {
		top += el.offsetTop;
		el = el.offsetParent;
	}
	return top;
}

router.afterEach(async (to, from) => {
	if (to.hash) {
		await sleep(100);
		const target = document.querySelector(to.hash);
		const top = calcDistanceToTop(target);
		document.documentElement.scrollTop = top;
	} else {
		document.documentElement.scrollTop = 0;
	}
});

export default router;
