export default {
  props: {
    visible: Boolean
  },
  data() {
    return {
      closeImg: require('./images/icon_close.svg'),
      qrcodeImg: require('./images/qrcode.png')
    };
  }
};