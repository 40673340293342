import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5a68bd2e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home view-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_CoreProductCapabilities = _resolveComponent("CoreProductCapabilities");
  const _component_MainApplicationScenarios = _resolveComponent("MainApplicationScenarios");
  const _component_ProfessionalServiceSupport = _resolveComponent("ProfessionalServiceSupport");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Banner), _createVNode(_component_CoreProductCapabilities), _createVNode(_component_MainApplicationScenarios), _createVNode(_component_ProfessionalServiceSupport)]);
}