import { useRouter } from 'vue-router';
import Header from './components/Header';
import Footer from './components/Footer';
export default {
  components: {
    Header,
    Footer
  },
  computed: {
    routePath() {
      return useRouter().currentRoute.value.path;
    }
  }
};