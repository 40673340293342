var _dec, _class;
import { Options, Vue } from 'vue-class-component';
import { OPTIONS } from './consts';
let View = (_dec = Options({
  data() {
    return {
      OPTIONS,
      activeList: OPTIONS.map(() => 0)
    };
  }
}), _dec(_class = class View extends Vue {}) || _class);
export { View as default };